import { PrgMenuItem } from '@prg/prg-core-lib';

/**
 * Constant that defines the menu items for main menu
 */
export const SIDEBAR_MENU_APP: PrgMenuItem[] = [
  /* new PrgMenuItem({
    id: 'dashboard',
    icon: 'pi pi-check',
    routerLink:['/dashboard-app'],
    routerLinkActiveOptions: { exact: true },
    translationBasePath: 'components.sidebar.',
  }),
  new PrgMenuItem({
    id: 'dashboard',
    icon: 'pi pi-check',
    routerLink:['/dashboard-app/d'],
    routerLinkActiveOptions: { exact: true },
    translationBasePath: 'components.sidebar.',
  }),*/

  new PrgMenuItem({
    id: 'home',
    icon: 'pi pi-home',
    routerLinkString: "['/' + this.authConfig.authRoutingLoginOkRedirectPath]",
    routerLinkActiveOptions: { exact: true },
    translationBasePath: 'components.sidebar.',
    permission: 'listself',
    resource: 'workorder',
  }),

  new PrgMenuItem({
    id: 'work-order',
    icon: 'pi pi-calendar',
    routerLinkActiveOptions: { exact: true },
    translationBasePath: 'components.sidebar.',
    permission: 'listself',
    resource: 'workorder',
    items: [
      new PrgMenuItem({
        icon: 'pi pi-exclamation-circle',
        id: 'overdue',
        routerLink: ['/work-order/list'],
        queryParams: { filter: 'overdue' },
        routerLinkActiveOptions: { exact: true },
        translationBasePath: 'components.sidebar.work-order.',
      }),
      new PrgMenuItem({
        icon: 'pi pi-cog',
        id: 'on-going',
        routerLink: ['/work-order/list'],
        queryParams: { filter: 'onGoing' },
        routerLinkActiveOptions: { exact: true },
        translationBasePath: 'components.sidebar.work-order.',
      }),
      new PrgMenuItem({
        icon: 'pi pi-calendar-times',
        id: 'planned-for-today',
        routerLink: ['/work-order/list'],
        queryParams: { filter: 'plannedForToday' },
        routerLinkActiveOptions: { exact: true },
        translationBasePath: 'components.sidebar.work-order.',
      }),
      new PrgMenuItem({
        icon: 'pi pi-calendar',
        id: 'scheduled',
        routerLink: ['/work-order/list'],
        queryParams: { filter: 'scheduled' },
        routerLinkActiveOptions: { exact: true },
        translationBasePath: 'components.sidebar.work-order.',
      }),
      new PrgMenuItem({
        icon: 'pi pi-check',
        id: 'finished',
        routerLink: ['/work-order/list'],
        queryParams: { filter: 'finished' },
        routerLinkActiveOptions: { exact: true },
        translationBasePath: 'components.sidebar.work-order.',
      }),
    ],
  }),

  new PrgMenuItem({
    id: 'auth',
    icon: 'pi pi-user',
    translationBasePath: 'components.sidebar.',
    permission: 'listself',
    resource: 'workorder',
    items: [
      new PrgMenuItem({
        icon: 'pi pi-id-card',
        id: 'my-account',
        routerLink: ['/user-profile/my-account'],
        routerLinkActiveOptions: { exact: true },
        visibleExpression: '!!this.user',
        translationBasePath: 'components.sidebar.auth.',
      }),
      new PrgMenuItem({
        icon: 'pi pi-sign-in',
        id: 'login',
        routerLinkString:
          "['/' + this.authConfig.authRoutingBasePath + '/' + this.authConfig.authRoutingLoginPath]",
        routerLinkActiveOptions: { exact: true },
        visibleExpression: '!this.user',
        translationBasePath: 'components.sidebar.auth.',
      }),
      new PrgMenuItem({
        icon: 'pi pi-book',
        id: 'signup',
        routerLinkString:
          "['/' + this.authConfig.authRoutingBasePath + '/' + this.authConfig.authRoutingSignupPath]",
        routerLinkActiveOptions: { exact: true },
        visibleExpression: '!this.user',
        translationBasePath: 'components.sidebar.auth.',
      }),
      new PrgMenuItem({
        icon: 'pi pi-key',
        id: 'request-pass',
        routerLinkString:
          "['/' + this.authConfig.authRoutingBasePath + '/' + this.authConfig.authRoutingRequestPasswordPath]",
        routerLinkActiveOptions: { exact: true },
        translationBasePath: 'components.sidebar.auth.',
      }),
      new PrgMenuItem({
        id: 'config-items-notifications',
        icon: 'pi pi-bell',
        translationBasePath: 'components.sidebar.',
        routerLinkString:
          // eslint-disable-next-line @typescript-eslint/quotes, max-len
          "['/' + this.configItemsConfig.configurationItemsRoutingBasePath + '/' + this.configItemsConfig.configurationItemsRoutingNotificationsPath]",
        routerLinkActiveOptions: { exact: true },
        permission: 'list-notifications',
        resource: 'configurationitem',
      }),
      new PrgMenuItem({
        icon: 'pi pi-sign-out',
        commandString: 'this.onClickLogout',
        id: 'logout',
        visibleExpression: '!!this.user',
        translationBasePath: 'components.sidebar.auth.',
      }),
    ],
  }),
  new PrgMenuItem({
    id: 'tasks-board',
    icon: 'pi pi-check-square',
    routerLink: ['/tasks-board'],
    routerLinkActiveOptions: { exact: true },
    translationBasePath: 'components.sidebar.',
    permission: 'menu',
    resource: 'ticket',
  }),
];

/**
 * Constant that defines the menu items for language menu on header
 */
